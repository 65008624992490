.react-pdf__Page {
    display: flex;
    justify-content: center;
    height: 100% !important;
}

.react-pdf__Page__canvas {
    border: 2px solid #000;
}

.react-pdf__Document {
    flex: 1;
}