/* Define theme palette colours */
$mdc-theme-primary: #353551;
$mdc-theme-primary-light: #605f7d;
$mdc-theme-primary-dark: #0e0f28;
$mdc-theme-secondary: #7cc68d;
$mdc-theme-secondary-light: #adf9bd;
$mdc-theme-secondary-dark: #4c955f;
$mdc-theme-background: #c2c2cb;
$mdc-theme-primary-text: #000000;
$mdc-theme-secondary-text: #000000;

$mdc-theme-primary-50: #e7e7ea;
$mdc-theme-primary-100: #c2c2cb;
$mdc-theme-primary-200: #9a9aa8;
$mdc-theme-primary-300: #727285;
$mdc-theme-primary-400: #53536b;
$mdc-theme-primary-500: #353551;
$mdc-theme-primary-600: #30304a;
$mdc-theme-primary-700: #282840;
$mdc-theme-primary-800: #222237;
$mdc-theme-primary-900: #161627;

/* Define theme */
$accent-color: $mdc-theme-secondary;
$primary-color: $mdc-theme-primary;
$card-border-colour: #E0E0E0;
$icon-color: $mdc-theme-primary;
$link-colour: #1a73e8;
$light-grey-background: #EEEEEE;

/* Define Padding  */
$padded-content-width: 1124px !important;
$default-card-padding: 24px;

/* Define Margins  */
$default-page-margin: 48px;

/* Define RetroKit Values  */
$toolbar-retrokit-logo-width: 40px;

$header-height: 50px;
$row-height: 40px;
$row-border-width: 1px;
$hover-color: cornsilk;

$retrokit-card-action-button-padding: 16px;
$retrokit-card-header-padding: 16px;
$retrokit-card-header-margin: 8px;

$element-vertical-spacing: 16px;
$login-card-padding: 48px;
$card-width: 100%;
$retrokit-logo-width: 100px;

$graphs-card-padding: 16px;
