.legend h3 {
  font-size: 0.8em;
  font-weight: bold;
}

.legend .label {
  margin-left: 5px;
  vertical-align: top;
}

.legend .legend-item {
  height: 20px;
}

.legend-table {
  width: 100%
}

.opacity-slider-wrapper {
  text-align:center
}