@import '../../../../../../node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
@import '../../../../../../node_modules/mapbox-gl/dist/mapbox-gl.css';

@import "../../../../../SassVariables";


#map-dwelling-popup-kpis, #map-ed-popup-details, #map-area-popup-details {
  padding-top: 16px;
  margin-bottom: -5px;
}

#map-dwelling-popup-kpi-table, #map-ed-popup-details-table, #map-area-popup-details-table {
  width: 100%;
  font: 12px/20px Helvetica Neue,Arial,Helvetica, sans-serif;
}

#map-dwelling-popup-address, #map-ed-popup-label, #map-area-popup-label {
  margin: -10px;
  padding: 8px;
  font-size: 13px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.map-dwelling-popup-kpi-table-column {
  text-align: center;
}

.mapboxgl-popup-content {
  position: relative;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  pointer-events: auto;
  border: 1px solid $mdc-theme-primary;
}

.dwelling-popup.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.dwelling-popup.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.dwelling-popup.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
.ed-popup.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.ed-popup.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.ed-popup.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: $mdc-theme-primary;
}
.dwelling-popup.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.dwelling-popup.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.dwelling-popup.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.ed-popup.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.ed-popup.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.ed-popup.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip{
  border-top-color: $mdc-theme-primary;
}
.dwelling-popup.mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.ed-popup.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: $mdc-theme-primary;
}
.dwelling-popup.mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
.ed-popup.mapboxgl-popup-anchor-right .mapboxgl-popup-tip{
  border-left-color: $mdc-theme-primary;
}

.mapboxgl-style-list
{
  display: none;
}

.mapboxgl-ctrl-group .mapboxgl-style-list button
{
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 8px 8px 6px;
  text-align: right;
  width: 100%;
  height: auto;
}

.mapboxgl-style-list button.active
{
  font-weight: bold;
}

.mapboxgl-style-list button:hover
{
  background-color: rgba(0, 0, 0, 0.05);
}

.mapboxgl-style-list button + button
{
  border-top: 1px solid #ddd;
}

